@import "./variables.scss";
.api-container {
    .api-header {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        .header-left {
            flex: 1;
            .api-method {
                padding: 0.3rem;
                background-color: $color-coral-primary;
                color: white;
                border-radius: 5px;
                text-transform: uppercase;
                margin-right: min(2vw, 2rem);
                font-size: 0.8rem;
            }
            .api-title {
                font-weight: 500;
            }
        }
        .header-right {
            display: flex;
            justify-content: flex-end;
            gap: 1rem;
            .api-url {
                font-size: 0.8rem;
                cursor: pointer;
                color: $color-coral-primary;
                .url {
                    background-color: transparent;
                    width: min-content;
                    border: none;
                    font-size: 0.8rem;
                    cursor: pointer;
                    margin-right: 0.5rem;
                }
            }
            .extras {
                min-width: 2vw;
                .icon {
                    color: $color-blue-primary;
                }
            }
        }
    }
    .main-api-content {
        font-size: 0.8rem;
    }
    .title {
        font-weight: 600;
        margin-top: 1rem;
    }
    .url-example {
        font-size: 0.8rem;
        text-decoration: underline;
    }
    .api-response {
        padding: 0.3rem;
        background-color: rgba($color: $color-coral-primary, $alpha: 0.1);
        color: $color-coral-primary;
    }
}
.export-options-container {
    background-color: white;
    box-shadow: $regular-box-shadow-extra-blurry;
    display: flex;
    flex-direction: column;
    padding: 0.3rem;
    .export-option {
        padding: 0.3rem 1rem;
        min-width: 5vw;
        color: $color-blue-primary;
        background-color: transparent;
        border: none;
        cursor: pointer;
        &:hover {
            background-color: $color-blue-secondary;
            color: white;
        }
    }
}

@media (min-width: $min-width-small-tablet) {
    .api-container {
        .api-header {
            flex-direction: row;
        }
    }
}