@import "./variables.scss";
.sensor-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    .sensor {
        text-decoration: none;
        box-shadow: $regular-box-shadow-extra-blurry;
        .image-container {
            height: 15rem;
            overflow: hidden;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .data-container {
            display: flex;
            padding: 1rem 0;
            .data-left-container {
                flex: 1;
                p.heading {
                    font-size: 1.3rem;
                    font-weight: 500;
                }
                p.sub-heading {
                    font-weight: 500;
                    text-transform: capitalize;
                }
            }
            .data-right-container {
                p.temperature {
                    font-size: 1.3rem;
                }
            }
        }
    }
}

@media (min-width: $min-width-small-tablet) {
    .sensor-container {
        align-items: start;
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (min-width: $min-width-large-tablet) {
    .sensor-container {
        grid-template-columns: repeat(3, 1fr);
    }
}