@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Laila:wght@300;400;500;600;700&family=Merienda:wght@300;400;500;600;700;800;900&family=Volkhov:ital,wght@0,400;0,700;1,400;1,700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import "variables";
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
    // background-color: black !important;
    // color: white;
}
.only-tablet {
    display: none;
}
.app {
    background-color: #fcfcfc;
}
.main-content {
    margin-top: 1rem;
    .content-header {
        display: flex;
        flex-direction: column;
        align-items: start;
        width: 90%;
        margin: 1rem auto;
        .page-title {
            flex: 1;
            sub {
                font-size: 0.8rem;
                color: $color-grey-primary;
                font-weight: 400;
            }
        }
        .organization-title {
            font-size: 1.2rem;
            font-weight: 500;
            color: $color-coral-primary;
        }
        .button {
            justify-self: flex-end;
        }
    }
    .main-container {
        width: 90%;
        margin: 0 auto;
    }
}

.button {
    padding: 1rem 1.5rem;
    background: transparent;
    text-decoration: none;
    transition: 0.4s all $common-transition-function;
    &:enabled {
        cursor: pointer;
    }
    &:disabled {
        cursor: not-allowed;
    }
    &.button-primary {
        padding: 0.5rem 1rem;
        border-radius: 8px;
        color: white;
        background-color: $color-blue-primary;
        border: 1px solid $color-blue-primary;
        &:hover {
            box-shadow: 0 0 8px $color-blue-primary;
        }
    }
    &.button-secondary {
        padding: 0.5rem 1rem;
        border-radius: 8px;
        color: $color-blue-primary;
        border: 1px solid $color-blue-primary;
    }
    &.button-regular {
        color: $color-blue-primary;
        border: 1px solid $color-blue-primary;
        border-radius: 8px;
        &:enabled {
            background-color: $color-blue-primary;
            color: white;
        }
    }
    &.button-simple {
        border: none;
        background-color: transparent;
        padding: 0.3rem;
        position: absolute;
        right: 0.3rem;
        bottom: 0rem;

    }
}

.overlay-loader-container {
    position: fixed;
    top: 0;
    left: 0;
    z-index: $overlay-loader-z-index;
    background-color: rgba($color: #000000, $alpha: 0.45);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: opaqueUp 0.4s;
    .overlay-loader {
        background-color: #fff;
        padding: 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        border-radius: 5px;
        box-shadow: 0 0 30px rgba($color: #000000, $alpha: 0.5);
        // transform: scale(0);
        animation: scaleUp 0.4s;
    }
}
.embed-loader-container {
    background-color: transparent;
    width: 100%;
    height: -webkit-fill-available;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: opaqueUp 0.4s;
    .embed-loader {
        background-color: #fff;
        padding: 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        border-radius: 5px;
        box-shadow: 0 0 30px rgba($color: #000000, $alpha: 0.5);
        // transform: scale(0);
        animation: scaleUp 0.4s;
    }
}
.embed-loading-container-outer {
    width: 100%;
    height: 50vh;
}

@keyframes opaqueUp {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes scaleUp {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}

@media (min-width: $min-width-small-tablet) {
    .only-tablet {
        display: block;
    }
}

@media (min-width: $min-width-large-tablet) {
    .only-mobile {
        display: none;
    }
    .main-content {
        width: 90%;
    }
    .app {
        display: grid;
        grid-template-columns: 15% 85%;
        .main-header {
            grid-column: 1/-1;
        }
        nav.side-nav-container {
            grid-column: 1/2;
        }
        .main-content {
            grid-column: 2/3;
        }
        footer {
            grid-column: 1/-1;
        }
    }
}