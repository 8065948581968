@import "./variables.scss";
header.main-header {
    // outline: 1px solid red;
    background: white;
    box-shadow: 0 3px 5px rgba($color: #000000, $alpha: 0.3);
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.3rem;
    padding: 0.3rem 1rem;
    button, .option {
        border: none;
        background: transparent;
        padding: 0.1rem;
        min-width: 1.5rem;
    }
    .hamburger-initiater {
        button {
            font-size: 1.8rem;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .logo-container {
        display: flex;
        align-items: center;
        img {
            max-width: 3rem;
            width: 100%;
        }
        .name-container {
            margin-left: 0.5rem;
            h2, h3 {
                font-size: 0.9rem;
                font-weight: 500;
            }
            h3 {
                font-size: 0.7rem;
            }
        }

    }
    .main-options {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 0.3rem;
        .bell {
            button {
                font-size: 1rem;
                padding: 0.3rem;
            }
        }
        button {
            display: flex;
            justify-content: center;
            align-items: center;
            // outline: 1px solid red;
        }
    }
}

.notification-container {
    background-color: white;
    color: black;
    box-shadow: $regular-box-shadow-extra-blurry;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0.5rem;
    min-width: 30%;
    max-width: 25rem;
    width: min(80%, 25rem);
    height: min(30rem, 50vh);
    overflow-y: auto;
    border-radius: 8px;
    .notification {
        padding: 0.3rem;
        .sub-text {
            color: $color-grey-primary;
        }
    }
}

@media (min-width: $min-width-small-tablet) {
    header.main-header {
        // outline: 1px solid red;
        box-shadow: 0 3px 5px rgba($color: #000000, $alpha: 0.3);
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 0.3rem;
        padding: 0.3rem 1rem;
        button, .option {
            border: none;
            background: transparent;
            padding: 0.3rem;
            min-width: 2.5rem;
        }
        .hamburger-initiater {
            button {
                font-size: 1.8rem;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
        .logo-container {
            display: flex;
            align-items: center;
            img {
                max-width: 3rem;
                width: 100%;
            }
            .name-container {
                margin-left: 0.5rem;
                h2, h3 {
                    font-size: 0.9rem;
                    font-weight: 500;
                }
                h3 {
                    font-size: 0.7rem;
                }
            }
    
        }
        .main-options {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 0.3rem;
            .bell {
                button {
                    font-size: 1rem;
                    padding: 0.3rem;
                }
            }
            button {
                display: flex;
                justify-content: center;
                align-items: center;
                // outline: 1px solid red;
            }
        }
    }
}