@import "./variables.scss";
nav.side-nav-container {
    position: fixed;
    left: 0;
    z-index: $z-index-nav-side;
    width: 70%;
    height: 100%;
    transform: translateX(-100%);
    // outline: 1px solid red;
    box-shadow: $regular-box-shadow;
    background: white;
    transition: 0.4s all $common-transition-function;
    // animation: slide-in 1s;
    &.nav-open {
        transform: none;
    }
    .side-nav {
        .links-container {
            padding: 1rem 0;
            display: flex;
            flex-direction: column;
            gap: 1rem;
            .link {
                color: $color-blue-primary;
                text-decoration: none;
                padding: 1rem;
                transition: 0.4s all $common-transition-function;
                &.active {
                    background-color: $color-blue-primary;
                    color: white;
                }
                .link-name {
                    padding-left: 0.3rem;
                }
            }
        }
    }
}

// @keyframes slide-in {
//     from {
//         transform: translate();
//     }
// }

@media (min-width: $min-width-large-tablet) {
    nav.side-nav-container {
        position: sticky;
        top: 0;
        height: 100vh;
        width: 100%;
        transform: none;
        .side-nav {
            .links-container {
                padding: 1rem 0;
                display: flex;
                flex-direction: column;
                gap: 1rem;
                .link {
                    color: $color-blue-primary;
                    text-decoration: none;
                    padding: 1rem;
                    transition: 0.4s all $common-transition-function;
                    &:hover {
                        background-color: $color-grey-secondary;
                    }
                    &.active {
                        background-color: $color-blue-primary;
                        color: white;
                    }
                    .link-name {
                        padding-left: 0.3rem;
                    }
                }
            }
        }
    }
}