@import './variables.scss';
.main-content.sensor {
    background-color: #fff;
    border-bottom-left-radius: 60px;
    border-bottom-right-radius: 60px;
    padding: 1rem;
    overflow: hidden;
    .header {
        .title {
            font-size: 2rem;
            font-weight: 700;
            margin: 0;
            margin-bottom: 1rem;
            font-family: 'Volkhov', sans-serif;
            text-align: center;
        }
    }
    .cards-container {
        display: grid;
        grid-template-columns: repeat(1, auto);
        gap: 2rem;
        .weather-card {
            width: 80%;
            margin: 0 auto;
            box-shadow: 20px 20px 150px 0px rgba(124, 122, 122, 0.25);
            transition: 0.4s all cubic-bezier(0.23, 1, 0.320, 1);
            &:hover {
                transform: translateY(-2%);
            }
            .ant-card-cover {
                .image-container {
                    width: 100%;
                    img {
                        width: 100%;
                    }
                }
            }
            .ant-card-body {}
        }
    }
    .graphs-container {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        row-gap: 3rem;
        column-gap: 10%;
        margin: 2rem 0;
        .child {
            box-shadow: 20px 20px 150px 0px rgba(23, 23, 23, 0.25);
            min-height: 20vh;
            border-radius: 30px;
            overflow: hidden;
            &.graph {
                min-height: 60vh;
                display: flex;
                flex-direction: column;
                background: #fff;
                padding: 1rem;
                iframe {
                    flex: 1;
                }
                .select {
                    padding: 1rem;
                    padding-top: 0;
                    align-self: flex-end;
                    .range {
                        margin-right: 0.5rem;
                    }
                }
            }
            &.aqi {
                height: 70vh;
            }
        }
        .info-small {
            position: fixed;
            bottom: 0.3rem;
            right: 0.3rem;
            padding: 0.5rem;
            padding-right: 1.5rem;
            background-color: $color-coral-primary;
            border-radius: 5px;
            color: white;
            .exit {
                position: absolute;
                top: 1px;
                right: 1px;
                font-size: 1rem;
            }
        }
        .sensor-summary {
            text-align: center;
            .location {
                text-align: center;
                font-size: 1.5rem;
                font-weight: 600;
                margin-top: -0.5rem;
            }
            .timezone-offset {
                margin-bottom: 1rem;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 0.3rem;
                font-size: 1rem;
                margin-left: auto;
                .image-container {
                    height: 0.8rem;
                    img {
                        vertical-align: top;
                        height: 100%;
                    }
                }
            }
            .sensor-summary-inner {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 1rem;
                .sensor-info{
                    .value {
                        text-transform: capitalize;
                        font-weight: 600;
                        display: block;
                        font-size: 1.3rem;
                        // text-align: center;
                    }
                }
                .lat-lon-container {
                    display: flex;
                    flex-direction: column;
                    gap: 0.3rem;
                    align-items: center;
                    .icon {
                        font-size: 2rem;
                        color: $color-blue-primary;
                        text-align: center;
                    }
                    .lat-lon-data {
                        font-size: 1.3rem;
                        font-weight: 500;
                    }
                }
            }
        }
        .weather-summary {
            padding-bottom: 0;
            .image-container-particular {
                width: 100%;
                height: 5rem;
                margin-top: 1rem;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }
    .weather-summary-container {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        color: #000;
        &:hover {
            color: #000;
        }
        .location-container {
            display: flex;
            justify-content: flex-start;
            font-size: 1.1rem;
            .district {
                &::after {
                    margin-left: 0.3rem;
                    content: '>';
                }
            }
            .city {
                margin-left: 0.3rem;
                font-weight: 600;
            }
            .timezone-offset {
                display: flex;
                gap: 0.3rem;
                align-self: flex-start;
                justify-self: flex-end;
                font-size: 0.8rem;
                margin-left: auto;
                .image-container {
                    height: 0.7rem;
                    img {
                        height: 100%;
                    }
                }
            }
        }
        .time {
            font-size: 0.75rem;
            font-weight: 400;
            color: $color-grey-primary;
            position: absolute;
            bottom: -1rem;
            left: 0rem;
            line-height: 1;
        }
        &.child {
            .time {
                right: 0rem;
                left: initial;
            }
        }
        .summary {
            display: grid;
            grid-template-columns: repeat(2, auto);
            row-gap: 1rem;
            column-gap: 1rem;
            position: relative;
            .aqi-box {
                display: grid;
                grid-template-columns: min-content auto;
                column-gap: 0.5rem;
                row-gap: 0;
                .value {
                    font-family: 'Laila', sans-serif;
                    font-size: 1.8rem;
                    font-weight: 700;
                    grid-row: 1/3;
                    line-height: 1.6;
                    white-space: nowrap;
                }
                .lable {
                    color: rgba(68, 68, 68, 1);
                    // line-height: 0.8;
                }
                .time {
                    position: static;
                }
            }
            .temperature {
                font-family: 'Laila', sans-serif;
                font-size: 1.5rem;
                font-weight: 600;
                span {
                    font-size: initial;
                    font-weight: initial;
                    vertical-align: top;
                    color: rgba(68, 68, 68, 1);
                }
            }
            .weather-element {
                display: grid;
                grid-template-columns: min-content auto;
                row-gap: 0.3rem;
                column-gap: 0.5rem;
                align-items: center;

                .lable {
                    grid-column: 1/3;
                    color: rgba(68, 68, 68, 1);
                    font-weight: 300;
                    margin: 0;
                    text-transform: uppercase;
                    &.capitalize {
                        text-transform: capitalize;
                    }

                }
                .circle {
                    width: 1rem;
                    height: 1rem;
                    border-radius: 50%;
                    &.circle-red {
                        background-color: rgba(249, 95, 95, 1);
                    }
                    &.circle-green {
                        background-color: rgba(10, 175, 155, 1);
                    }
                    &.circle-blue {
                        background-color: rgba(48, 172, 242, 1);
                    }
                    &.circle-yellow {
                        background-color: rgba(241, 196, 15, 1);
                    }
                }
                .value {
                    justify-self: flex-start;
                    font-family: 'Laila', sans-serif;
                    font-size: 1.5rem;
                    font-weight: 600;
                    margin: 0;
                    white-space: nowrap;
                    span {
                        font-size: 0.8rem;
                        vertical-align: top;
                        font-weight: 400;
                    }
                }
            }
        }
        .buttons-container {
            display: flex;
            justify-content: flex-end;
            gap: 1rem;
            .button {
                font-size: 1rem;
                padding: 0.5rem 1.7rem;
                margin-top: 0.5rem;
                font-weight: 500;
            }
        }
    }
}

@media (min-width: $min-width-small-tablet) {}
@media (min-width: $min-width-large-tablet) {
    .main-content.sensor {
        width: 100%;
        padding: 1rem 5%;
        border-bottom-left-radius: 100px;
        border-bottom-right-radius: 100px;
        .graphs-container {
            grid-template-columns: repeat(2, 1fr);
            .child {

            }
            .graph {
                grid-column: 1/-1;
            }
        }
    }
}

@media (min-width: $min-width-large-tablet) {
    .home-main {
        border-bottom-left-radius: 100px;
        border-bottom-right-radius: 100px;
        .title {
            &.search {
    
            }
            .icon {
                margin-left: 0.5rem;
            }
        }
        .cards-container {
            grid-template-columns: repeat(2, 1fr);
            gap: calc(2rem + 2vw);
            column-gap: calc(4rem + 4vw);
            width: min(900px, 60%);
            margin: 0 auto;
            .weather-card {
                width: 100%;
                .ant-card-body {}
            }
        }
    }
    .weather-summary-container {
        .summary {
            grid-template-columns: repeat(3, auto);
            // .aqi-box {
            //     grid-column: 1/3;
            // }
        }
    }
}