@import "./variables.scss";
.ant-spin-nested-loading > div > .ant-spin {
    max-height: initial !important;
}
.Login {
    background: linear-gradient(101.62deg,#05175f 13.85%,rgba(79,3,115,.99) 78.02%);
    main.login {
        width: 100%;
        height: 100vh;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        .login-container {
            background: #fff;
            width: 90%;
            margin: 0 auto;
            padding: 3rem 0;
            padding-bottom: 4rem;
            animation: comeHere 0.4s;
            position: relative;
            .tag {
                position: absolute;
                top: 1rem;
                right: 1rem;
                padding: 0.3rem 1rem;
                background-color: #05175f;
                border-radius: 20px;
                color: white;
            }
            .image-container {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                .logo-container {
                    width: 5rem;
                    img {
                        width: 100%;
                    }
                }
                .name-container {
                    h1.title {
                        font-size: 1.3rem;
                        font-weight: 600;
                        font-family: "Poppins", sans-serif;
                    }
                }
            }
            .welcome-back {
                margin-bottom: 1rem;
                h2.title {
                    font-size: 1rem;
                    text-align: center;
                    font-weight: 500;
                }
                p.text {
                    text-align: center;
                    color: rgba(134, 134, 134, 1);
                    font-size: 0.8rem;
                    span {
                        color: #05175f;
                        font-weight: 600;
                    }
                }
            }
            .login-form {
                form {
                width: fit-content;
                margin: 0 auto;
                display: flex;
                flex-direction: column;
                gap: 1rem;
                .input-container {
                    border: 1px solid rgba(134, 134, 134, 1);
                    padding: 5px 8px;
                    display: flex;
                    width: 90%;
                    margin: 0 auto;
                    label {
                        color: rgba(134, 134, 134, 1);
                    }
                    input {
                        border: none;
                        width: 100%;
                    }
                }
                .button-container {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    button {
                        background: #f06548;
                        color: #fff;
                        border: none;
                        padding: 0.5rem 2rem;
                        cursor: pointer;
                        transition: 0.2s background cubic-bezier(0.215, 0.610, 0.355, 1);
                        &:hover {
                            background: #f06548;
                        }
                    }
                }
            }
            }
        }
    }
}
@keyframes comeHere {
    0% {
        transform: scale(0);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@media (min-width: $min-width-small-tablet) {
    .Login {
        main.login {
            .login-container {
                width: 50%;
                .image-container {
                    .name-container {
                        h1.title {
                            font-size: 1.5rem;
                        }
                    }
                }
                .welcome-back {
                    h2.title {
                        font-size: 1.3rem;
                    }
                    p.text {
                        font-size: 1rem;
                    }
                }
            }
        }
    }
}