@import "./variables.scss";
.overlay-prompt {
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.3);
}
.download-prompt {
    position: fixed;
    z-index: 9999;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    padding: 1rem;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    box-shadow: 0 0 5px rgba($color: #000000, $alpha: 0.3);
    background-color: white;
    .checkbox-group {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 1rem;
    }
}


@media (min-width: $min-width-small-tablet) {
    .download-prompt {
        width: calc(15rem + 10vw);
    }
}