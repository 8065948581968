@import "./variables.scss";


footer {
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    color: #fff;
    font-family: 'Laila', sans-serif;
    padding: 2rem 0;
    background-image: linear-gradient(180deg,#05175f 13.85%,rgba(79,3,115,.99) 78.02%);
    .top, .copy-text {
        width: 80%;
        margin: 0 auto;
    }
    .top {
        padding-bottom: 1rem;
        border-bottom: 1px solid rgba(240, 101, 72, 0.5);
        display: grid;
        grid-template-columns: auto;
        gap: 2rem;
        .get-in-touch {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            .title {
                font-size: 1.3rem;
                font-weight: 500;
                font-family: 'Inter', sans-serif;
            }
            .description {
                color: rgba(203, 203, 203, 1);
            }
            .links-container {
                margin-top: 1rem;
                display: flex;
                gap: 1rem;
                .link {
                    color: #fff;
                    font-size: 1.5rem;
                }
            }
        }
        .logo-container {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 1rem;
            align-self: flex-start;
            .title {
                font-size: 1.5rem;
                font-weight: 700;
            }
            .logo {
                width: 10rem;
                height: 4rem;
                img {
                    width: 100%;
                    // height: 100%;
                    object-fit: contain;
                }
            }
        }
        .internal-links {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            .title {
                font-family: 'Inter', sans-serif;
                font-size: 1.3rem;
                font-weight: 500;
            }
            .links {
                display: flex;
                gap: 1rem;
                flex-wrap: wrap;
                .link {
                    color: #fff;
                }
            }
        }
    }
    .copy-text {
        font-family: 'Laila', sans-serif;
        font-weight: 500;
        text-align: center;
        color: rgb(186, 186, 186);
        .bold {
            color: #fff;
        }
    }
}




@media (min-width: $min-width-small-tablet) {
    footer {
        position: relative;
        z-index: $z-index-footer;
        padding: 3rem 0;
        .internal-links {
            .links {
                .link {
                    &:hover {
                        color: $color-green-primary;
                    }
                }
            }
        }
        .get-in-touch {
            .links-container {
                .link {
                    &:hover {
                        color: $color-green-primary;
                    }
                }
            }
        }
        .top, .copy-text {
            width: 80%;
        }
        .top {
            grid-template-columns: repeat(3, 1fr);
            .get-in-touch {
                grid-column-start: 1;
                grid-row-start: 1;
            }
            .logo-container {
                flex-direction: column-reverse;
            }
        }
        .copy-text {
            font-family: 'Laila', sans-serif;
            font-weight: 500;
            text-align: center;
        }
    }
    .message-box-container {
        &.shown {
            width: 40%;
        }
        .message-box-initiater {
            width: 3.5rem;
            height: 3.5rem;
            font-size: 2rem;
        }
        .message-box {
            height: 50vh;
            .form {
                form {
                    button.submit {
                        &:hover {
                            transform: translate(5px, -5px);
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: $min-width-large-tablet) {
    .message-box-container {
        &.shown {
            width: 40%;
        }
        .message-box {
            height: 50vh;
        }
    }
}
@media (min-width: $min-width-small-laptop) {
    .message-box-container {
        &.shown {
            width: 30%;
        }
        .message-box {
            height: 75vh;
        }
    }
}
@media (min-width: $min-width-large-laptop) {
    .message-box-container {
        &.shown {
            width: 25%;
        }
        .message-box {
            height: 70vh;
        }
    }
}