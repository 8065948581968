@import "./variables.scss";
.profile-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    background: white;
    padding: 1rem;
    border-radius: 8px;
    .profile-image-container {
        flex: 2;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin: 0 auto;
        .profile-image {
            border-radius: 50%;
            overflow: hidden;
        }
    }
    .profile-details-container {
        form {
            width: 100%;
        }
        flex: 8;
        .detail-group {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 1rem;
        }
        .detail {
            flex: 1;
            .title {
                font-weight: 500;
            }
            input {
                width: 100%;
                padding: 0.5rem 1rem;
                border: 1px solid black;
                border-radius: 8px;
                &:disabled {
                    background-color: transparent;
                    border-color: transparent;
                }
            }
            margin-bottom: 1rem;
        }
        .button-container {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 1rem;
            margin: 1rem 0;
            .button {
                font-size: 1rem;
                min-width: 10rem;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 1rem;
            }
        }
    }
}

@media (min-width: $min-width-small-laptop) {
    .profile-container {
        flex-direction: row;
        gap: max(5rem, 10vw);
        align-items: stretch;
        padding: 3rem;
        .profile-image-container {
            flex: 4;
            .profile-image {
                width: 10rem;
                height: 10rem;
            }
        }
    }
}