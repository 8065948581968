.loading-graph-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    gap: 1rem;
    .image-container {
        width: 5rem;
        height: 5rem;
        animation: rotate 1s infinite;
        img {
            width: 100%;
        }
    }
    .text {
        font-size: 1rem;
        text-align: center;
    }
}
.recharts-tooltip {
    background-color: white;
    min-width: 10rem;
    padding: 1rem;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba($color: #000000, $alpha: 0.3);
    .title {
        font-size: 1.2rem;
        color: rgb(136, 132, 216);
    }
    .value {
        font-weight: 600;
    }
    .date {
        text-align: right;
        font-size: 0.8rem;
    }
}

@keyframes rotate {
    0% {
        transform: rotateZ(0deg);
    } 50% {
        transform: rotateZ(180deg);
    } 100% {
        transform: rotateZ(360deg);
    }
}