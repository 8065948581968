@import "./variables.scss";
.message-page {
    .page-header {
        padding: 0.5rem 1rem;
        display: flex;
        .right-container {
            display: grid;
            grid-template-columns: repeat(2, auto);
            column-gap: 1rem;
            .image-container {
                width: 2rem;
                height: 2rem;
                border-radius: 50%;
                overflow: hidden;
                border: 1px solid green;
                grid-row: 1/3;
                align-self: center;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .title {
                font-weight: 500;
            }
            .dot::before {
                content: "";
                background-color: green;
                width: 0.8rem;
                height: 0.8rem;
                border-radius: 50%;
                display: inline-block;
                font-size: 0.8rem;
                margin-right: 0.5rem;
            }
        }
    }
    .page-body {
        .message-box {
            display: flex;
            flex-direction: column;
            align-items: stretch;
            justify-content: stretch;
            background-color: #05175f;
            border-radius: 10px;
            height: auto;
            min-height: 80vh;
            margin: 0 auto;
            animation: open-up 0.4s;
            .title {
                padding: 0.3rem 1rem;
            }
            .messages, .form {
                padding: 0.3rem;
            }
            .messages {
                box-shadow: inset -10px 10px 10px rgba($color: #000000, $alpha: 0.1);
                color: black;
                background-color: white;
                overflow-y: auto;
                overflow-x: hidden;
                flex: 1;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                gap: 1rem;
                .no-messages {
                    padding: 0.3rem 1rem;
                    margin: 0 auto;
                    background-color: $color-coral-primary;
                    color: white;
                    border-radius: 10px;
                    font-size: 0.8rem;
                }
                .message {
                    display: grid;
                    grid-template-columns: auto 2rem;
                    gap: 0.3rem;
                    width: 80%;
                    .text {
                        border: 1px solid $color-blue-primary;
                        border-radius: 10px;
                        .subject, .text-message {
                            padding: 0.3rem;
                        }
                        .subject {
                            font-size: 0.8rem;
                            border-bottom: 1px solid black;
                        }
                    }
                    .image-container {
                        width: 2rem;
                        height: 2rem;
                        border-radius: 50%;
                        border: 1px solid$color-blue-primary;
                        overflow: hidden;
                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                    .extra {
                        position: relative;
                        top: -0.5rem;
                        font-size: 0.8rem;
                        color: white;
                        background-color: $color-blue-primary;
                        width: fit-content;
                        border-radius: 8px;
                        float: right;
                        justify-self: end;
                        padding: 2px 0.5rem;
                        grid-column: 1/-1;
                        text-transform: capitalize;
                    }
                }
            }
            .form {
                .intro {
                    font-size: 0.8rem;
                    position: relative;
                    padding: 0.5rem;
                    .exit {
                        cursor: pointer;
                        position: absolute;
                        top: 0;
                        right: 0;
                        transform: translate(-0%, -50%);
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 1rem;
                        border: 2px solid $color-blue-primary;
                        background-color: white;
                    }
                    .text {
                        color: white;
                    }
                }
                form {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0.5rem;
                    padding-right: 0;
                    .inputs {
                        flex: 1;
                        display: flex;
                        flex-direction: column;
                        gap: 5px;
                    }
                    textarea, input {
                        resize: none;
                        flex: 1;
                        border-radius: 10px;
                        padding: 0.3rem;
                    }
                    button.submit {
                        cursor: pointer;
                        border: none;
                        padding: 0.3rem 0.8rem;
                        background-color: transparent;
                        color: white;
                        font-size: 1.3rem;
                        transition: 0.4s all $common-transition-function;
                    }
                }
            }
        }
    }
}
@keyframes open-up {
    from {
        opacity: 0;
        transform: translateY(110%);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@media (min-width: $min-width-small-tablet) {
    .message-box-initiater {
        width: 3.5rem;
        height: 3.5rem;
        font-size: 2rem;
    }
    .message-box {
        .form {
            form {
                button.submit {
                    &:hover {
                        transform: translate(5px, -5px);
                    }
                }
            }
        }
    }
}

@media (min-width: $min-width-large-tablet) {
    .message-box {
    }
    .message-page {
        .page-body {
            .message-box {
                .messages {
                    box-shadow: inset -10px 10px 10px rgba($color: #000000, $alpha: 0.1);
                    .no-messages {
                        padding: 0.3rem 1rem;
                        margin: 0 auto;
                        background-color: $color-coral-primary;
                        color: white;
                        border-radius: 10px;
                        font-size: 0.8rem;
                    }
                    .message {
                        width: 40%;
                        .extra {
                            right: 2rem;
                        }
                    }
                }
                .form {
                    form {
                        align-items: flex-end;
                    }
                }
            }
        }
    }
}
@media (min-width: $min-width-small-laptop) {
    .message-box {
    }
}
@media (min-width: $min-width-large-laptop) {
    .message-box {
    }
}